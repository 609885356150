const config = {
  identityPoolId: "eu-central-1:cd4b0a83-1555-4e00-b2fd-cf6dd9c1e999",
  region: "eu-central-1",
  userPoolId: "eu-central-1_ALhwMpILG",
  userPoolWebClientId: "67gf5bquprjrh7b9t0gc3uoqeq",

  oauth: {
    //scope: ["email", "profile", "openid"],
    responseType: "code", // or "token", note that REFRESH token will only be generated when the responseType is code
    domain: "nyb-portal-aoi.auth.eu-central-1.amazoncognito.com",
    redirectSignIn: "https://aoi.leanpay.dev.bankszamlakivonat.hu/",
    redirectSignOut: "https://aoi.leanpay.dev.bankszamlakivonat.hu/"
  },

  API: {
    endpoints: [
      {
        name: "RestApi",
        endpoint: "https://rest.aoi.dev.bankszamlakivonat.hu",
        custom_header: async () => {
          return { Authorization: "token" };
        }
      },
        {
        name: "SocketApi",
        endpoint: "wss://socket.aoi.dev.bankszamlakivonat.hu"
      }
    ]
  }
};

export default config;
